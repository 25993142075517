<template>
	<div class="notice">
		<div class="header">
			<div>裁判员管理></div>
			<div class="current">裁判员风采</div>
		</div>

		<div class="information">
			<div class="img">
				<img src="../assets/image/rotation.png" alt="">
			</div>
			<div class="briefintroduction-box">
				<div class="title">北京裁判员风采-武术套路国际级裁判员张继东</div>
				<div class="date">2023/06/03</div>
				<div class="briefintroduction">北京裁判员风采-武术套路国际级裁判员张继东北京裁判员风采-武术套路国际级裁判员张继东北京裁判员风采-武术套路国际级裁判员
					张继东</div>
				<div style="text-align: -webkit-right;">
					<div class="button">
						<div class="name">查看详情</div>
						<div class="more">
							<img src="../assets/image/more.png" alt="">
						</div>
					</div>
					<div class="horizontalline"></div>
				</div>
			</div>
		</div>
		
		<div class="information">
			<div class="img">
				<img src="../assets/image/rotation.png" alt="">
			</div>
			<div class="briefintroduction-box">
				<div class="title">北京裁判员风采-武术套路国际级裁判员张继东</div>
				<div class="date">2023/06/03</div>
				<div class="briefintroduction">北京裁判员风采-武术套路国际级裁判员张继东北京裁判员风采-武术套路国际级裁判员张继东北京裁判员风采-武术套路国际级裁判员
					张继东</div>
				<div style="text-align: -webkit-right;">
					<div class="button">
						<div class="name">查看详情</div>
						<div class="more">
							<img src="../assets/image/more.png" alt="">
						</div>
					</div>
					<div class="horizontalline"></div>
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10"></pagination>
		</div>

	</div>
</template>
<script>
	export default {
		name: 'notice',
		components: {},
		data() {
			return {
				total:0
			}
		},
		created() {},
		methods: {
			currentchange(val) {
				console.log(val)
			},
			prevclick(val) {
				console.log(val)
			},
			nextclick(val) {
				console.log(val)
			},

		}
	}
</script>
<style  lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 70PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 37PX;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.information {
		margin-left: 70PX;
		display: flex;
		margin-bottom: 18PX;
	}

	.img {
		width: 189PX;
		height: 170PX;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.briefintroduction-box {
		margin-left: 19PX;
	}

	.briefintroduction-box .title {
		font-size: 20PX;
		font-weight: 500;
		color: #164B92;
		margin-top: 10PX;
		cursor: pointer;
	}

	.briefintroduction-box .date {
		font-size: 18PX;
		font-weight: 400;
		color: #8A8A8A;
		margin-top: 19PX;
	}

	.briefintroduction-box .briefintroduction {
		font-size: 15PX;
		font-weight: 400;
		color: #8A8A8A;
		margin-top: 13PX;
	}

	.briefintroduction-box .button {
		margin-top: 19PX;
		display: flex;
		align-items: center;
		justify-content: end;
		cursor: pointer;
	}

	.briefintroduction-box .button .name {
		font-size: 16PX;
		font-weight: 400;
		color: #8A8A8A;
	}

	.briefintroduction-box .button .more {
		width: 9PX;
		height: 12.8PX;
		margin-left: 7PX;
		display: flex;
		align-items: center
	}

	.horizontalline {
		width: 86PX;
		height: 1PX;
		border: 1PX solid #8A8A8A;
		margin-top: 4PX;
	}

	.paging {
		margin-top: 66PX;
		margin-bottom: 133PX;
		display: flex;
		margin-left: 80PX;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}
</style>